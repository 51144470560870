import React from "react";
import { Typography, CardContent } from "@mui/material";
import { StyledCard, StyledImg } from "./styles";
import { AspectRatio } from "@mui/joy";
import { Link } from "react-router-dom";

const MountboardsCard = (mountboard) => {
  const { title, imageUrl } = mountboard.mountboard;
  return (
      <StyledCard>
        <AspectRatio minHeight="120px" maxHeight="200px">
          <StyledImg src={imageUrl} alt="" />
        </AspectRatio>
        <CardContent orientation="horizontal">
          <div>
            <Typography level="body-xs">{title}</Typography>
          </div>
          <Link
            to={{
              pathname: `/products/${title.replace(/\s/g, "")}`,
              state: { title: title },
            }}
            style={{ textDecoration: "none", color: "black" }}
          >
          </Link>
        </CardContent>
      </StyledCard>
  );
};

export default MountboardsCard;
