import React from "react";
import { Typography, CardContent, Button } from "@mui/material";
import { StyledCard, StyledDiv } from "./styles";
import { AspectRatio } from "@mui/joy";
import { Link } from "react-router-dom";

const CategoriesCard = ({category}) => {
  const { title, description, imageUrl } = category;
  return (
    <StyledDiv>
      <StyledCard>
        <div style={{ margin: "10px" }}>
          <Typography level="title-lg">{title}</Typography>
        </div>
        <AspectRatio minHeight="120px" maxHeight="200px">
          <img src={imageUrl} loading="lazy" alt="" />
        </AspectRatio>
        <CardContent orientation="horizontal">
          <div>
            <Typography level="body-xs">{description}</Typography>
          </div>
          <Link
            to={{
              pathname: `/products/${title.replace(/\s/g, "")}`,
              state: { title: title },
            }}
            style={{ textDecoration: "none", color: "black" }}
          >
            <Button
              variant="solid"
              size="md"
              color="primary"
              aria-label="Explore Bahamas Islands"
              sx={{ ml: "auto", alignSelf: "center", fontWeight: 600 }}
            >
              Browse
            </Button>
          </Link>
        </CardContent>
      </StyledCard>
    </StyledDiv>
  );
};

export default CategoriesCard;
