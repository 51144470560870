import React from 'react';
import ReadingAidsCard from './ReadingAidsCard';
import { StyledItem } from './style';
const ReadingAidsRoot = () => {
    return (
      <StyledItem>
      <ReadingAidsCard title={'A4'} 
      imageUrl={"https://images.ctfassets.net/gd2wssucjpvj/7mLCdQCN8B4SX8dgb4xBtI/9fc38fe8868ac6a672e725f9594ba8da/a4-overlay.jpg"}
      description={'210 x 297 mm - 12 Colours'}/>
      <ReadingAidsCard title={'A3'} 
      imageUrl={"https://images.ctfassets.net/gd2wssucjpvj/7377wiSSj5VZ2Swonb6HLD/fda3d5e0dad3ea1ba6f86e030c8c1fdf/a3-overlay.jpg"}
      description={'297 x 420 mm - 12 Colours'}/>
      <ReadingAidsCard title={'Ruler'} 
      imageUrl={"https://images.ctfassets.net/gd2wssucjpvj/5VWKhgogYLAaE7OI4WXiPE/68e3876cee2f203ff73e4c213b49fb81/readingruler.jpg"}
      description={'210 x 80 cm - 12 Colours'}/>
      </StyledItem>
      
  );
};

export default ReadingAidsRoot;