import styled from "@emotion/styled";
import { breakpoints } from "../../themes/config";
export const StyledDiv = styled.div`
  padding: 50px;
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  justify-items: center;
  @media (max-width: ${breakpoints[2]}px) {
    flex-direction: column;
  }
  @media (min-width: ${breakpoints[2]}px) and (max-width: 1111px) {
    flex-direction: column;
  }
  @media (min-width: 1111px) and (max-width: 1321px) {
    flex-direction: row;
  }
  @media (min-width: 1321px) {
    flex-direction: row;
  }
`;

export const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 820px) {
    flex-direction: column;
    font-size: 14px;
  }
`;

export const StyledText = styled.p`
  font-size: 24px;
  font-family: Poppins;

  @media (max-width: 630px) {
    font-size: 16px;
  }
`;

export const ProductContainer = styled.p`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
  @media (max-width: 790px) {
    padding-top: 0px;
  }
`;
