import React from "react";
import { Typography, CardContent, CardMedia } from "@mui/material";
import { StyledDiv } from "../../categoriesCard/styles";
import { StyledDetails, StyledFoamboardCard } from "./styles";

const FoamboardItem = ({foamboard}) => {
  const {
    title,
    size,
    packQuantity,
    imageUrl,
  } = foamboard;
  return (
    <StyledDiv>
          <StyledFoamboardCard>
          <div style={{ margin: "10px" }}>
          <Typography level="title-lg">{title}</Typography>
        </div>
          {/* <StyledTitle gutterBottom variant="h5" component="div">
          {title}
        </StyledTitle> */}
      <CardMedia
        component="img"
        alt="Foam board"
        image={imageUrl}
        margin={"3px"}
      />
      <CardContent>

        <div style={{ margin: "10px" }}>
          <StyledDetails>Size: {size}</StyledDetails>
          <StyledDetails>Pack Quantity: {packQuantity}</StyledDetails>
        </div>
      </CardContent>
    </StyledFoamboardCard>
    </StyledDiv>
  );
};

export default FoamboardItem;
