import React from "react";
import "./style.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="site-footer">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <h6>Simplex</h6>
            <p className="text-justify" style={{ marginRight: "6px" }}>
              Cut through the clutter, communicate with confidence. Simplex
              offers a comprehensive range of wholesale visual communication
              materials, including premium foamboards, mount boards, and
              reading aids. We bridge the gap between complex ideas and
              effortless understanding. Our innovative products help you craft
              impactful displays, enhance learning experiences, and elevate your
              branding with ease.
            </p>
          </div>

          <div className="col-xs-6 col-md-3">
            <h6>Categories</h6>
            <ul className="footer-links">
              <li>
                <Link to="/products">FoamBoards</Link>
              </li>
              <li>
                <Link to="/products">Mountboards</Link>
              </li>
              <li>
                <Link to="/products">Reading aids</Link>
              </li>
            </ul>
          </div>

          <div className="col-xs-6 col-md-3">
            <h6>Quick Links</h6>
            <ul className="footer-links">
              <li>
                <a href="../#contact">About Us</a>
              </li>
              <li>
                <a href="../#contact">Contact Us</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container">
        <div>
          <div className="col-md-8 col-sm-6 col-xs-12">
            <p className="copyright-text">
              Copyright &copy; 2017 All Rights Reserved by{"  "}
              <Link href="../">Simplex</Link>.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
