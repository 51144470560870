import React from 'react';
import { StyledP } from './styles';

const AboutUs = props => {
    return (
        <StyledP id="about">
        <h1>About us</h1>
        <p>
          Simplex - Your trusted provider of high-quality visual communication materials,
          based in Leicester, England. We are passionate about creating
          innovative solutions that enhance learning, communication, and visual
          appeal across a range of products, including foamboards, mountboards,
          dyslexia-friendly reading aids, and integrated labels.
        </p>
        <p>
          Our commitment to excellence drives us to deliver products and
          services that exceed expectations, ensuring reliability and
          dependability for our customers. We are dedicated to empowering our
          partners to succeed, providing expert guidance and support throughout
          their projects.
        </p>
        </StyledP>
    );
};


export default AboutUs;