import React from 'react'
import { StyledCard, StyledDiv } from './style';
import { CardContent, Typography } from '@mui/material';
import { AspectRatio } from '@mui/joy';

const ReadingAidsCardNoBrowse = ({title, imageUrl, description}) => {
  return (
     
    <StyledDiv>
      <StyledCard>
        <div style={{ margin: "10px" }}>
          <Typography level="title-lg">{title}</Typography>
        </div>
        <AspectRatio minHeight="120px" maxHeight="200px">
          <img src={imageUrl} loading="lazy" alt="" />
        </AspectRatio>
        <CardContent orientation="horizontal">
          <div>
            <Typography level="body-xs">{description}</Typography>
          </div>
        </CardContent>
      </StyledCard>
    </StyledDiv>
  );
}

export default ReadingAidsCardNoBrowse