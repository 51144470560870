import React from "react";
import { useNavigate } from "react-router-dom";
import {
    StyledDiv, NewDiv, StyledHeader,
    StyledP,
    StyledButton
} from "./styles";

const HeroBannerSmall = () => {
    const nav = useNavigate()
  return (
    <StyledDiv>
      <NewDiv>
        <StyledHeader>
          Visual impact,
          <br /> Simplified.
          <br /> Simplex.
        </StyledHeader>
        <StyledP>
          Foam boards, Mount boards, reading aids - presenting made
          easy.
        </StyledP>
      </NewDiv>
        <StyledButton onClick={()=>{nav("/products")}}>Explore</StyledButton>
    </StyledDiv>
  );
};

export default HeroBannerSmall;
