import { Card } from "@mui/material";
import styled from "@emotion/styled";


export const StyledDiv = styled.div`
  padding: 50px;
  /* width: 75%; */
  display: grid;
  font-family: Poppins;
`;

export const StyledCard = styled(Card)`
    justify-self: center;
    width: 100%;
    @media (max-width: 490px) {
    width: 220px;
  }
    @media (min-width: 490px) and (max-width: 553px) {
    width: 300px;
  }
    @media (min-width: 553px) and (max-width: 660px) {
    width: 350px;
  }
  @media (min-width: 660px) and (max-width: 1111px) {
    width: 300px;
  }
  @media (min-width: 1111px) and (max-width: 1321px) {
    width: 200px;
  }
  @media (min-width: 1321px) {
    width: 250px;
  }
`;

export const StyledDivItems = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(4, 0.5fr);
  @media (max-width: 599px) {
    grid-template-columns: repeat(1, 0.5fr);

  }
  @media (min-width: 600px) and (max-width: 767px) {
    grid-template-columns: repeat(1, 0.5fr);

  }

  @media (min-width: 768px) and (max-width: 1023px) {
    grid-template-columns: repeat(2, 0.5fr);

  }

  @media (min-width: 1024px) and (max-width: 1350px) {
    grid-template-columns: repeat(3, 0.5fr);

  }

  @media (min-width: 1280px) {
    grid-template-columns: repeat(4, 0.5fr);

  }
`;

export const StyledItem = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
justify-self: center;

@media (max-width: 1110px) {
  flex-direction: column;
  }

`