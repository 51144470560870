import { createTheme } from '@mui/system';

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1080, // your custom breakpoint
      xl: 1536,
    },
  },
});
export const breakpoints = [300, 375, 444, 588, 684, 972, 1270]
export const beaconBreakpoints = [375, 414, 600, 960, 1280]
