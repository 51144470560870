import React from "react";
import { useCategories } from "../api/contentApi";
import CategoriesCard from "../components/categoriesCard/CategoriesCard";
import { CircularProgress } from "@mui/material";
import {
  ProductContainer,
  StyledText,
  StyledTextContainer,
} from "../components/products/styles";
import ecofriendly from "../components/products/eco-friendly-svgrepo-com.svg";
import whitelabel from "../components/products/label-svgrepo-com.svg";
import styled from "styled-components";
import { breakpoints } from "../themes/config";

const StyledDiv = styled.div`
  justify-content: center;
  padding: 50px;
  display: flex;
  @media (max-width: ${breakpoints[2]}px) {
    flex-direction: column;
  }
  @media (min-width: ${breakpoints[2]}px) and (max-width: 1111px) {
    flex-direction: column;
  }
  @media (min-width: 1111px) and (max-width: 1321px) {
    flex-direction: row;
  }
  @media (min-width: 1321px) {
    flex-direction: row;
  }
`;
const Products = () => {
  const { categories, loading, error } = useCategories();
  if (loading)
    return (
      <div
        style={{
          height: "900px",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <CircularProgress size={100} />
      </div>
    );
  if (error) return <div>Error: {error.message}</div>;
  return (
    <ProductContainer>
      <StyledDiv>
        {categories.map((category, index) => (
          <CategoriesCard key={index} category={category} />
        ))}
      </StyledDiv>
      <div
        style={{ width: "80%", textAlign: "justify", paddingBottom: "20px" }}
      >
        <StyledTextContainer>
          <img
            src={ecofriendly}
            alt="eco friendly"
            style={{ height: "40px", marginRight: "30px" }}
          />
          <StyledText>
            Our product range is a reflection of our commitment to quality,
            sustainability, and innovation. We offer a diverse selection of
            products, each designed with meticulous attention to detail and a
            focus on sustainability. We believe that it's possible to offer
            great products without compromising on environmental responsibility.
          </StyledText>
        </StyledTextContainer>
        <StyledTextContainer>
          <img
            src={whitelabel}
            alt="eco friendly"
            style={{ height: "40px", marginRight: "30px" }}
          />
          <StyledText>
            We are proud to offer white-label services, allowing businesses to
            customize our products with their branding. This service is a
            testament to our flexibility and our commitment to supporting
            businesses in their growth.
          </StyledText>
        </StyledTextContainer>
      </div>
    </ProductContainer>
  );
};
export default Products;
