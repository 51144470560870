import React from "react";
import { Typography, CardContent, Button } from "@mui/material";
import { FbStyledCard, FbStyledDiv } from "./styles";
import { AspectRatio } from "@mui/joy";

const NewFoamBoardsCard = ({title,color}) => {
  const imgUrl =
  color === "White"
    ? "https://images.ctfassets.net/gd2wssucjpvj/1sJfbJe9q0EO7YbBrc2ka4/aca4a64c2c54fd2286e8dcafc75d4cd9/foamboards.jpeg"
    : "https://images.ctfassets.net/gd2wssucjpvj/55nbGC3yCa1KusqtLzTvmE/ddfbe2f838a71cd469cd52267048e4f5/fb5mmB.jpeg";
  return (
    <FbStyledDiv>
      <FbStyledCard>
        <div style={{ margin: "10px" }}>
          <Typography level="title-lg">{title}</Typography>
        </div>
        <AspectRatio minHeight="120px" maxHeight="200px">
          <img src={imgUrl} loading="lazy" alt="" />
        </AspectRatio>
        <CardContent orientation="horizontal">
            <Button
              variant="solid"
              size="md"
              color="primary"
              aria-label="Explore Bahamas Islands"
              sx={{ ml: "auto", alignSelf: "center", fontWeight: 600 }}
            >
              Browse
            </Button>
        </CardContent>
      </FbStyledCard>
    </FbStyledDiv>
  );
};

export default NewFoamBoardsCard;