import React from "react"
import HomepageCard from "../components/homepageCard/HomepageCard"
import ContactUs from "../components/contactUs/ContactUs"
import HeroBanner from "../components/HeroBanner/HeroBanner"
import { useMediaQuery } from 'react-responsive'; // Correct path
import HeroBannerSmall from "../components/HeroBannerSmall/HeroBannerSmall"


const HomePage = () => {
    const isSmallScreen = useMediaQuery({ maxWidth: 789 });

    return (
        <>
      {isSmallScreen ? <HeroBannerSmall /> : <HeroBanner />}
    <HomepageCard/>
    <ContactUs/>
        </>
    )
}
export default HomePage