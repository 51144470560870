import  styled from "styled-components"

export const StyledP = styled.div`
  font-size: 24px;
  display: flex;
  flex-direction: column;
  background: "#f4f7f8";
  color: rgba(30, 37, 94);

  width: 100%;
  justify-content: center;
  text-align: justify;
  word-break: calc();
  margin-right: 30px;
@media (max-width: 946px) {
    text-align: justify;
    
  }
  @media (max-width: 600px) {
    font-size: 16px;
    
  }
`