import styled from "@emotion/styled"
import { StyledTextContainer } from "../styles"
import { StyledCard } from "../../categoriesCard/styles"
import { Typography, Card } from "@mui/material"
import { breakpoints } from "../../../themes/config"
export const StyledDiv = styled.div`
font-family: 'Poppins';
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: start;
    width: 100%;
    @media (max-width: 948px) {
   flex-direction: column;
  }
`
export const StyledFoamboardDiv = styled.div`
  padding: 50px;
  display: flex;
  width: 80%;
  @media (max-width: ${breakpoints[2]}px) {
    flex-direction: column;
  }
  @media (min-width: ${breakpoints[2]}px) and (max-width: 1111px) {
    flex-direction: column;
  }
  @media (min-width: 1111px) and (max-width: 1321px) {
    flex-direction: row;
  }
  @media (min-width: 1321px) {
    flex-direction: row;
  }
`;

export const StyledDivItems = styled.div`
  display: grid;
  grid-gap: 10px;
  @media (max-width: 599px) {
    grid-template-columns: repeat(1, 0.5fr);

  }
  @media (min-width: 600px) and (max-width: 767px) {
    grid-template-columns: repeat(1, 0.5fr);

  }

  @media (min-width: 768px) and (max-width: 1023px) {
    grid-template-columns: repeat(2, 0.5fr);

  }

  @media (min-width: 1024px) and (max-width: 1279px) {
    grid-template-columns: repeat(3, 0.5fr);

  }

  @media (min-width: 1280px) {
    grid-template-columns: repeat(4, 0.5fr);

  }
`;

export const StyledProduct = styled.div`
display: grid;
flex-direction: column;
justify-content: center;
justify-items: center;
text-align: justify;
font-size: 22px;
`
export const StyledItem = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: center;
justify-self: center;
`

export const StyledRichText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 80%;
    justify-self: center;
    line-height: 2cap;
    font-size: 20px;
    padding-top: 50px;

    @media (max-width: 767px) {
      font-size: 14px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 18px;
  }

  @media (min-width: 1024px) and (max-width: 1279px) {
    font-size: 18px;
  }

  @media (min-width: 1280px) {
    font-size: 20px;
  }
    
`

export const StyledHeader = styled.h1`
 justify-content:center;

  @media (min-width: 789px) {
    margin-top: 150px;
    
  }
`

export const StyledText = styled.p`
  font-size: 24px;
  font-family: Poppins;

  @media (max-width: 630px) {
    font-size: 16px;
  }
`;

export const StyledTextBox= styled(StyledTextContainer)`
  /* padding-top: 50px; */
  display: flex;

  flex-direction: row;
  align-items: center;
  width: 80%;

  @media (max-width: 820px) {
    flex-direction: column;
    font-size: 14px;
  }
`
export const StyledIcon = styled.img`
    height: 40px;
    @media (min-width: 789px) {
     padding-right: 30px;

  }
`

export const StyledFoamboardCard = styled(StyledCard)`
text-decoration: none;
        @media (max-width: 490px) {
    width: 250px;
  }
    @media (min-width: 490px) and (max-width: 553px) {
    width: 300px;
  }
    @media (min-width: 553px) and (max-width: 660px) {
    width: 300px;
  }
  @media (min-width: 660px) and (max-width: 949px) {
    width: 250px;
  }
  @media (min-width: 949px) and (max-width: 1180px) {
    width: 250px;
  }
  @media (min-width: 1180px) and (max-width: 1310px) {
    width: 250px;
  }
  @media (min-width: 1470px) {
    width: 250px;
  }
`

export const StyledTitle= styled(Typography)`
    font-size: 18px;
    font-family: Poppins;
    @media (max-width: 553px) {
    font-size: 16px;

  }
`
export const StyledDetails= styled(Typography)`
    font-size: 16px;
    font-family: 'Poppins';
    @media (max-width: 553px) {
    font-size: 14px;
  }
`

export const FbStyledDiv = styled.div`
  padding: 50px;
  display: grid;
`;

export const FbStyledCard = styled(Card)`
    justify-self: center;
    width: 100%;
    @media (max-width: 490px) {
    width: 220px;
  }
    @media (min-width: 490px) and (max-width: 553px) {
    width: 300px;
  }
    @media (min-width: 553px) and (max-width: 660px) {
    width: 350px;
  }
  @media (min-width: 660px) and (max-width: 1111px) {
    width: 400px;
  }
  @media (min-width: 1111px) and (max-width: 1321px) {
    width: 200px;
  }
  @media (min-width: 1321px) {
    width: 250px;
  }
`;


