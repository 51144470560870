import { Card } from "@mui/material";
import { breakpoints } from "../../themes/config";
import  styled  from "styled-components";

export const StyledDiv = styled.div`
width: 80%;
position: relative;
padding-top: 50px;
padding-bottom: 50px;
align-items: center;

left: 10%;
`
export const StyledCardDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`
export const cardBreakpoints = () => {

}
export const StyledCard = styled(Card)`
    @media (min-width: ${breakpoints[1] }px) and (max-width: ${breakpoints[2]}px) {
    height: 176px;
  }

    @media (min-width: ${breakpoints[2] }px) and (max-width: ${breakpoints[3]}px) {
    height: 204px;
  }
  @media (min-width: ${breakpoints[3] }px) and (max-width: ${breakpoints[4]}px) {
    height: 276px;
  }
  @media (min-width: ${breakpoints[4] }px) and (max-width: ${breakpoints[5]}px) {
    height: 324px;
  }
  @media (min-width: ${breakpoints[6]}px) {
    minWidth: 200;
    maxWidth: 300;
  }
`