import React from 'react';
import { useParams, Link,  } from 'react-router-dom';
import { StyledDiv } from './styles';
import NewFoamBoardsCard from './NewFoamBoardsCard';

const FoamboardsThickness = () => {
    const params = useParams()
    return (
        <StyledDiv>
            <Link to={"3mm"} style={{textDecoration: "none"}}>
        <NewFoamBoardsCard title={"3mm"} color={params.color} />
      </Link>
      <Link to={"5mm"} style={{textDecoration: "none"}}>
        <NewFoamBoardsCard title={"5mm"} color={params.color} />
      </Link>
      <Link to={"10mm"} style={{textDecoration: "none"}}>
        <NewFoamBoardsCard title={"10mm"} color={params.color} />
      </Link>
        </StyledDiv>
    );
};

export default FoamboardsThickness;