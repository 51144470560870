import styled from "styled-components"
import { breakpoints } from "../../themes/config";

export const StyledDiv = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  position: relative;
    width: 80%;
    left: 10%;
  @media (max-width: 973px) {
    flex-direction: column;
  }
`;

export const StyledForm = styled.form`
  width: auto;
  margin: 10px auto;
  padding: 25px 20px;
  background: #f4f7f8;
  border-radius: 8px;

  /* border: 4px solid rgba(30, 37, 94); */
  /* font-family: "Nunito", sans-serif; */
  color: #384047;
  margin-bottom: 50px;

  @media (min-width: ${breakpoints[4]}px) and (max-width: ${breakpoints[5]}px) {
    width: 600px;
  }
  @media (min-width: ${breakpoints[6]}px) {
    width: 600px;
  }
`;
