import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import "@fontsource/poppins"; // Defaults to weight 400
import { Button } from "@mui/material";

export const StyledDiv = styled.div`
  padding-top: 40px; /* Accommodate navbar height */
  position: relative; /* Establish stacking context */
  left: 10%;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
`;
export const StyledPDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  align-items: flex-end;
`;
export const StyledLink = styled(NavLink)`
  text-decoration: none;
  align-self: flex-end;
`;

export const StyledA = styled.a`
  text-decoration: none;
  align-self: flex-end;
`;

export const StyledP = styled.p`
  padding: 0 20px;
  font-size: 18px;
  font-weight: 500;
  color: #1E255E;
  :hover{
    color: #17a4d0;
  }
`;

export const StyledButton = styled(Button)`
  width: 130px;
  height: 42px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #17a4d0;
  background: none;
  color: #17a4d0;
  text-align: center;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  line-height: normal;
`;

export const StyledPortalButton = styled(Button)`
  width: 100px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
  background: none;
  color: #17a4d0;
  border: 1px solid #17a4d0;
  :hover {
    color: #ddf7ff;
    background: #17a4d0;
  }
`;
