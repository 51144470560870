import React from "react";
import { Outlet } from "react-router-dom";
import MainNavigation from "../components/navbar/MainNavigation";
import Footer from "../components/footer/Footer";
import Navbar from "../components/newNav/Navbar";
import { useMediaQuery } from 'react-responsive'; // Correct path

const RootLayout = () => {
  const isSmallScreen = useMediaQuery({ maxWidth: 789 });

  return (
    <>
      {isSmallScreen ? <MainNavigation /> : <Navbar />}
      <Outlet />
      <Footer />
    </>
  );
};

export default RootLayout;