import {createBrowserRouter, RouterProvider} from 'react-router-dom'
import HomePage from './pages/home';
import Products from './pages/products';
import RootLayout from './pages/root';
import ErrorPage from './pages/Error';
import FoamboardsHome from './components/products/foamboards/FoamboardsHome';
import FoamboardsRoot from './components/products/foamboards/FoamboardsRoot';
import FoamboardsThickness from './components/products/foamboards/FoamboardsThickness';
import FoamboardsDisplay from './components/products/foamboards/FoamboardsDisplay';
import Mountboards from './components/products/mountboards/Mountboards';
import ReadingAids from './components/products/readingAids/ReadingAids';
import ReadingAidsDisplay from './components/products/readingAids/ReadingAidsDisplay';
import ReadingAidsRoot from './components/products/readingAids/ReadingAidsRoot';

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout/>,
    errorElement: <ErrorPage/>,
    children: [
      {index: true, element: <HomePage/>},
      {path:'/products', element: <Products/>},
      {path:'/products/Foamboards', element: <FoamboardsRoot/>, children:[
        {path:'', element: <FoamboardsHome/>},
        {path:':color', element: <FoamboardsThickness/>},
        {path:':color/:thickness', element: <FoamboardsDisplay/>},
      ]},
      {path:'/products/mountboards', element: <Mountboards/>},
      {path:'/products/readingaids', element: <ReadingAids/>, children:[
        {path:'', element: <ReadingAidsRoot/>},
        {path:':size', element: <ReadingAidsDisplay/>}
      ]}
    ]
  }
])

function App() {

  return (
    <RouterProvider router={router}/>
  );
}

export default App;