import React from 'react';
import { StyledHeader, StyledIcon, StyledProduct, StyledRichText, StyledTextBox } from '../foamboards/styles';
import { Outlet } from 'react-router-dom';
import { useCategories } from '../../../api/contentApi';
import { StyledText } from '../styles';
import manufacturing from "../manufacturing.svg";
import ecoFriendly from "../eco-friendly-svgrepo-com.svg"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { CircularProgress } from '@mui/material';
const ReadingAids = () => {
  const { categories, loading, error } = useCategories();

  if (loading) {
    return (
      <div
      style={{
        height: "900px",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <CircularProgress size={100} />
    </div> 
    )       
  }
  if (error) {
    return <div>Error: {error.message}</div>; // Or your custom error component
  }
  if (categories) {
    const category = categories.find(foamboard => foamboard.title === 'Reading Aids')
    const {title, fullDescription, specs} = category

    return (
      <StyledProduct>
      <StyledHeader style={{ justifySelf: "center" }}>{title}</StyledHeader>
          <Outlet />
        <StyledTextBox>
        <StyledIcon
            src={manufacturing}
            alt="eco friendly"
          />
          <StyledText>
            {fullDescription}
          </StyledText>
        </StyledTextBox>
        <StyledTextBox>
          <StyledIcon
            src={ecoFriendly}
            alt="eco friendly"
          />
          <StyledText>
          We prioritize using materials that have minimal environmental impact,
           such as recycled content and renewable resources. Our manufacturing
            processes are optimized to reduce waste and energy consumption.
             By choosing our Reading, you can be confident that you are making
              an environmentally conscious choice without compromising on quality or performance.
          </StyledText>
        </StyledTextBox>
        <StyledRichText>
        {documentToReactComponents(specs)}
        </StyledRichText>
      </StyledProduct>
  );
  }
};

export default ReadingAids;