import React from "react";
import { useFoamboards } from "../../../api/contentApi";
import FoamboardItem from "./FoamboardItem";
import { useParams } from "react-router-dom";
import { StyledDivItems } from "./styles";



const FoamboardsDisplay = () => {
  
  const { foamboards, loading, error } = useFoamboards();
  const params = useParams();

  const filteredFoamboards = foamboards.filter(
    (foamboard) =>
      foamboard.thickness === params.thickness &&
      foamboard.colour === params.color
  );

  if (loading) {
    return (
        <div>LOADING</div>
    )
  }
  if (error) {
    return (
        <div>{error.message}</div>
    )
  }
  return (
      <StyledDivItems>
        {filteredFoamboards.map((foamboard, index) => (
          <FoamboardItem key={index} foamboard={foamboard} />

          ))}
      </StyledDivItems>
  );
};

export default FoamboardsDisplay;
