import styled from "styled-components";
import { breakpoints } from "../../../themes/config";
import { Card } from "@mui/material";
import { StyledTextContainer } from "../styles";

export const StyledDiv = styled.div`
    font-family: 'Poppins';
    display: grid;
    align-items: start;
    width: 100%;
    padding: 20px;
    justify-content: center;
`
export const StyledDivItems = styled.div`
  display: grid;
  grid-gap: 10px;
  padding-bottom: 50px;
  @media (max-width: 599px) {
    grid-template-columns: repeat(1, 0.5fr);

  }
  @media (min-width: 600px) and (max-width: 767px) {
    grid-template-columns: repeat(1, 0.5fr);

  }

  @media (min-width: 768px) and (max-width: 1023px) {
    grid-template-columns: repeat(2, 0.5fr);

  }

  @media (min-width: 1024px) and (max-width: 1279px) {
    grid-template-columns: repeat(3, 0.5fr);

  }

  @media (min-width: 1280px) {
    grid-template-columns: repeat(4, 0.5fr);

  }
`;

export const StyledProduct = styled.div`
display: grid;
flex-direction: column;
justify-content: center;
justify-items: center;
text-align: justify;

font-size: 22px;
`
export const StyledItem = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
justify-self: center;
`

export const StyledRichText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 80%;
    justify-self: center;
    font-size: 20px;
    padding-top: 50px;
    word-break: calc();
    @media (max-width: 767px) {
      font-size: 14px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 18px;
  }

  @media (min-width: 1024px) and (max-width: 1279px) {
    font-size: 18px;
  }

  @media (min-width: 1280px) {
    font-size: 20px;
  }

`
export const StyledCard = styled(Card)`
    justify-self: center;

    @media (max-width: 553px) {
    width: 250px;
  }
    @media (min-width: 553px) and (max-width: 660px) {
    width: 300px;
  }
  @media (min-width: 660px) and (max-width: 1111px) {
    width: 300px;
  }
  @media (min-width: 1111px) and (max-width: 1321px) {
    width: 300px;
  }
  @media (min-width: 1321px) {
    width: 300px;
  }
`;

export const StyledBanner = styled.div `
    display: flex;
    align-items: center;
    justify-self: center;
    justify-content: center;
    border-radius: 8px;
    width: 80%;
    overflow: hidden;

    /* @media (min-width: ${breakpoints[1] }px) and (max-width: ${breakpoints[2]}px) {
    height: 176px;
  }

    @media (min-width: ${breakpoints[2] }px) and (max-width: ${breakpoints[3]}px) {
    height: 204px;
  }
  @media (min-width: ${breakpoints[3] }px) and (max-width: ${breakpoints[4]}px) {
    height: 276px;
  }
  @media (min-width: ${breakpoints[4] }px) and (max-width: ${breakpoints[5]}px) {
    height: 324px;
  }
  @media (min-width: ${breakpoints[5]}px) {
    height: 468px;
    width: 80%;
    left: 10%;
    position: relative;
    align-self: center; */


  
`

export const StyledP = styled.p`
  font-size: 24px;
  font-family: Poppins;
  
  margin:0 50px;
  @media (max-width: 630px) {
    font-size: 16px;
  }
`
export const StyledTextBox= styled(StyledTextContainer)`
  display: flex;
  flex-direction: row;
  width: 80%;

  @media (max-width: 820px) {
    flex-direction: column;
    font-size: 14px;
  }
`

export const StyledImg = styled.img `
height: 100%;
border-radius: 8px;
 `

export const StyledText = styled.p`
  font-size: 24px;
  font-family: Poppins;
  justify-self: center;
  text-align: justify;
  word-break: calc();


  @media (max-width: 630px) {
    font-size: 16px;
  }
`;
export const StyledIcon = styled.img`
    height: 40px;
    @media (min-width: 789px) {
     padding-right: 30px;

  }
  
`