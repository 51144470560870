import styled from "@emotion/styled";
import bannerSvg from "./banner.svg";
import { Button } from "@mui/material";
export const StyledDiv = styled.div`
  width: 100%;
  height: 800px;
  background-image: url(${bannerSvg}); /* Use the imported SVG as background */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  //position: absolute;
  display: grid;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
`;

export const NewDiv = styled.div`
  width: 80%;
  height: 70%;
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-self: center;
`;

export const StyledDivLeft = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;
export const StyledDivRight = styled.div`
  display: grid;
  width: 50%;
`;
export const StyledImg = styled.img`
  //align-self: center;
  justify-self: right;
  width: 437px;
  height: 500px;
  flex-shrink: 0;
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, 0.1);
  padding-top: 50px;
  :hover {
    transition: transform 0.5s ease;
    transform: scale(
      1.05
    ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
`;

export const StyledHeader = styled.h1`
  color: #1e255e;
  font-family: Poppins;
  font-size: 56px;
  font-style: normal;
  font-weight: 1500;
  line-height: normal;
  padding-top: 50px;
`;
export const StyledP = styled.p`
  width: 405px;
  color: rgba(30, 37, 94, 0.7);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 50px;
`;

export const StyledButton = styled(Button)`
  width: 200px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 100px;
  color: white;
  background: #17a4d0;
  border: none;
  :hover {
    color: black;
  }
`;
