import axios from "axios";
import { useState, useEffect } from "react";
export const useCategories = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
          const response = await axios.get('https://api.pirmahomed.com/api/content/categories');
          setCategories(response.data);
          setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  return { categories, loading, error };
}


export const useFoamboards = () => {
  const [foamboards, setFoamboards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
          const response = await axios.get('https://api.pirmahomed.com/api/content/foamboards');
          setFoamboards(response.data);
          setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  return { foamboards, loading, error };
}

export const useMountboards = () => {
  const [mountboards, setMountboards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
          const response = await axios.get('https://api.pirmahomed.com/api/content/mountboards');
          setMountboards(response.data);
          setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  return { mountboards, loading, error };
}

export const useReading = () => {
  const [readingAids, setLabels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect( () => {
    const fetchData  = async ()=> {
      try {
          const response = await axios.get('https://api.pirmahomed.com/api/content/reading');
          setLabels(response.data);
          setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    }
    fetchData();
  },[]);
  return {readingAids, loading, error}
}
