import styled from "@emotion/styled";
import { Card } from "@mui/material";

export const StyledDiv = styled.div`
  padding: 50px;
  /* width: 75%; */
  display: grid;
  font-family: Poppins;
`;

export const StyledCard = styled(Card)`
    justify-self: center;
    width: 100%;
    @media (max-width: 490px) {
    width: 220px;
  }
    @media (min-width: 490px) and (max-width: 553px) {
    width: 300px;
  }
    @media (min-width: 553px) and (max-width: 660px) {
    width: 350px;
  }
  @media (min-width: 660px) and (max-width: 1111px) {
    width: 400px;
  }
  @media (min-width: 1111px) and (max-width: 1321px) {
    width: 200px;
  }
  @media (min-width: 1321px) {
    width: 250px;
  }
`;