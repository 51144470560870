import React from "react";
import { Link } from "react-router-dom";
import NewFoamBoardsCard from "./NewFoamBoardsCard";
import { StyledDiv } from "./styles";

const FoamboardsHome = () => {

  return (
    <StyledDiv>
      <Link to={"White"} style={{textDecoration: "none"}}>
        <NewFoamBoardsCard title={"White"} color={"White"} />
      </Link>
      <Link to={"Black"} style={{textDecoration: "none"}}>
        <NewFoamBoardsCard title={"Black"} color={"Black"} />
      </Link>
    </StyledDiv>
  );
};

export default FoamboardsHome;
